<template>
  <div class="flex flex-col w-full lg:w-1/2 py-24 lg:pr-20">
    <div class="flex justify-between items-center w-full mb-16">
      <div class="w-128">
        Crypto Ninja
      </div>

      <AppButton :to="{ name: routeNames.signup }">Sign Up</AppButton>
    </div>

    <div class="w-full max-w-lg m-auto lg:mx-0">
      <Form
        :validation-schema="loginEmailSchema"
        autocomplete="off"
        @submit="onSubmitForm"
      >
        <span class="text-14 text-blueGray-400">Sign In</span>
        <h4 class="mb-24 text-24">Join our community</h4>

        <AppInput
          id="email"
          v-model="loginForm.email"
          type="email"
          name="email"
          placeholder="email@example.com"
          label="Email"
          autofocus
          required
        />

        <AppInput
          id="password"
          v-model="loginForm.password"
          type="password"
          name="password"
          placeholder=""
          label="Password"
          autofocus
          required
        />

        <div class="flex items-center justify-center mb-24">
          <router-link :to="{ name: routeNames.forgot }" class="link text-14">
            Forgot password?
          </router-link>
        </div>

        <AppButton
          :loading="loading"
          variant="primary"
          type="submit"
          class="w-full"
        >
          Login
        </AppButton>
      </Form>
    </div>
  </div>
</template>

<script >
import { defineComponent, ref, reactive } from 'vue'
import { useRouter } from 'vue-router'

import { Form } from 'vee-validate'

import { loginEmailSchema } from '@/core/schemas'
import { store } from '@/store'
import { notification } from '@/core/helpers'
import { routeNames } from '@/router'

import AppInput from '@/components/stateless/AppInput.vue'
import AppButton from '@/components/stateless/AppButton.vue'

export default defineComponent({
  name: 'Login',

  components: { Form, AppButton, AppInput },

  setup () {
    const router = useRouter()

    const loading = ref(false)
    const loginForm = reactive({
      email: null,
      password: null
    })

    const onSubmitForm = async () => {
      loading.value = true

      try {
        await store.dispatch('authStore/login', loginForm)

        router.push({ name: routeNames.posts })
      } catch (err) {
        notification({ title: 'Login error!', errorCode: err.error.code })
      } finally {
        loading.value = false
      }
    }

    return {
      loading,
      loginForm,
      routeNames,
      loginEmailSchema,
      onSubmitForm
    }
  }
})
</script>
